import { createBrowserHistory } from 'history'

import { Main } from './Main'

import { Sentry } from './resources/Sentry'

import { createRoot } from 'react-dom/client'

const history = createBrowserHistory()

const Component = Sentry(history)(Main)

createRoot(document.getElementById('root')).render(
  <Component {...{ history }} />
)
