const IP_REGEX = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/

const { protocol, hostname, hash } = window.location
const [host] = window.location.host.split(':')
const [slug, port] = hash.split(':')
const shouldUseHash = process.env.NODE_ENV === 'development' && slug?.length > 1
const isIP = process.env.NODE_ENV === 'development' && IP_REGEX.test(host)

export const SLUG = (function () {
  if (shouldUseHash) {
    return slug.replace('#', '')
  }

  return hostname.replace('.com', '').split('.')[0]
})()

export const BASE_URL = (function () {
  if (isIP) {
    return `${protocol}//${host}`
  }

  return `${protocol}//api.${host.split('.').slice(-2).join('.')}`
})()

export const APP_URL = (function () {
  if (isIP) {
    return `${BASE_URL}:${port}/${SLUG}`
  }

  return BASE_URL + '/' + SLUG
})()

export const GRAPHQL_ENDPOINT = APP_URL + '/graphql'
