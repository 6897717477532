import React from 'react'
import classNames from 'classnames'
import './index.less'

export const Loader = ({
  className,
  fullscreen = false,
  isRelative,
  noMinHeight,
  text,
  white,
  size,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'loader',
        'spin-lg',
        white && 'loader--white',
        noMinHeight && 'loader--nominheight',
        isRelative && 'loader--relative',
        fullscreen && 'loader--fullscreen',
        size && `loader--${size}`,
        className
      )}
      {...props}
    >
      <span className="spin-dot spin-dot-spin">
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
      </span>
    </div>
  )
}
