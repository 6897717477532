import { BASE_URL } from '../constants/url'

import {
  browserProfilingIntegration,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
  init,
  withProfiler,
} from '@sentry/react'

export const Sentry = history => {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      browserProfilingIntegration(),
      reactRouterV5BrowserTracingIntegration({
        history,
      }),
      replayIntegration(),
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    tracePropagationTargets: [BASE_URL],
    replaysSessionSampleRate: 1,
    tracesSampleRate: 1,
    profilesSampleRate: 1,
  })

  return withProfiler
}
